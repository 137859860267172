import React from "react"
import { AccordionAlt } from "./AccordionAlt"
import { ArranQuote } from "../Misc/ArranQuote"
import { LogoSeperator } from "../Typography/Seperators"
import { CallMeBack } from "../Misc/CallMeBack"
import { CurveCarveTop } from "../Curves/Curve"
import { MarkdownCommon } from "../Markdown/MarkdownCommon"

export const AccordionCollection = ({
  items = [],
  variant = "white",
  staffCtaImage,
}) => {
  if (!items || items.length < 1) return null

  return (
    <div>
      <ArranQuote staffCtaImage={staffCtaImage} />
      <LogoSeperator />
      {/* Initial Curve: */}
      <CurveCarveTop variant={variant} background="primary" />
      {items.map((item, i) => (
        <React.Fragment key={item.id}>
          <AccordionAlt
            key={item.id}
            title={item.title}
            titleMobile={item.titleMobile}
            introduction={item.introduction}
            defaultOpen={item.isOpenByDefault}
            variant={variant}
          >
            <MarkdownCommon body={item.body} />
          </AccordionAlt>

          {/* Separation Curve - The Curve between each item, except last: */}
          {items.length !== i + 1 && (
            <CurveCarveTop variant={variant} background="light" />
          )}
        </React.Fragment>
      ))}
      {/* Final Curve: */}
      <CurveCarveTop variant="primary" background="light" />
      <div className="bg-primary py-4 pt-md-5 text-center">
        <CallMeBack btnLg />
      </div>
    </div>
  )
}
