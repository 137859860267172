import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { FaCheck } from "react-icons/fa"
import { BsEnvelope } from "react-icons/bs"
import { ModalPopUp } from "../Modal/ModalPopUp"
import NewsletterForm from "../Forms/NewsletterForm"

export const Newsletter = () => {
  return (
    <div className="py-5">
      <Container>
        <Row>
          <Col md={9} className="mx-auto">
            <Row>
              <Col md={8} className="d-flex align-items-center ">
                <div>
                  <p>WANT TO LEARN MORE ABOUT PROPERTY INVESTMENT?</p>
                  <p>SIGN UP TO OUR NEWSLETTER NOW!</p>
                </div>
              </Col>

              <Col md={4} className="d-flex flex-column align-items-center ">
                <BsEnvelope
                  size="75px"
                  className="d-block mb-3 text-secondary"
                />
                <ModalPopUp
                  trigger={
                    <Button variant="secondary" size="lg">
                      <FaCheck className="mr-2 mb-1" />
                      Sign Me Up
                    </Button>
                  }
                >
                  <NewsletterForm />
                </ModalPopUp>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
