import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export const ArranQuote = ({
  className = "bg-primary text-white",
  staffCtaImage = "Arran",
}) => {
  const data = useStaticQuery(graphql`
    query {
      Arran: file(relativePath: { eq: "sales-staff/arran.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      Ben: file(relativePath: { eq: "sales-staff/ben.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      Matt: file(relativePath: { eq: "sales-staff/matt.jpg" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className={`py-5 text-center ${className}`}>
      <Container>
        <Row>
          <Col md={9} className="d-flex align-items-center">
            <p className="lead mb-0">
              One Touch property consultants will share investment opportunities
              and guides with you to help you make an investment decision with
              confidence and ease.
            </p>
          </Col>

          <Col>
            <Img
              className="d-block mx-auto my-4 rounded-circle"
              fixed={data[staffCtaImage].childImageSharp.fixed}
              alt="Arran Kerkvliet - Head Shot"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
