/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React, { Fragment } from "react"
import { Link } from "gatsby"

import NetlifyForm from "./Template/NetlifyForm"
import CommonFields from "./Inputs/CommonFields"
import CurrentPage from "./Inputs/CurrentPage"
import MailingLists from "./Inputs/MailingLists"

const NewsletterForm = () => (
  <Fragment>
    <div className="text-center">
      <h2>Sign up to our newsletter</h2>
      <p>
        Enter your details below to sign up to our newsletter and receive latest
        industry news and new property releases in your preferred sector.
      </p>
    </div>
    <NetlifyForm name="newsletter-subscribe" btnText="Sign me up">
      <CommonFields />

      <CurrentPage />

      <MailingLists />
    </NetlifyForm>
    <div className="text-center small">
      <p>
        Your details are safe and secure with us. We hate spam and we will NEVER
        pass on your email to third parties
      </p>

      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  </Fragment>
)

export default NewsletterForm
