import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GuideTiles } from "../components/Guides/GuideTiles"
import { graphql, Link } from "gatsby"

import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"

import { AccordionCollection } from "../components/Accordion/AccordionCollection"
import { VideoTiles } from "../components/Guides/VideoTiles"
import { ArticleCard } from "../components/Article/ArticleCard"
import { Newsletter } from "../components/Misc/Newsletter"
import { truncate } from "../utils/truncate"

const Guidance = ({
  data: {
    contentfulGuidancePage: guidance,
    allContentfulArticle: { nodes: guides },
  },
}) => {
  // console.log(guides)
  return (
    <Layout>
      <SEO
        title="High Yield Property Investment – guides &amp; videos on rental returns"
        description={
          guidance.introduction &&
          truncate(guidance.introduction.introduction, 350, true)
        }
      />

      <Container>
        <Row>
          <Col>
            <h1>{guidance.title}</h1>
            {guidance.introduction && (
              <div
                className="content-body"
                dangerouslySetInnerHTML={{
                  __html: guidance.introduction.childMarkdownRemark.html,
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
      <CurveCarveTop variant="light" background="white" />
      <div className="bg-light pt-5">
        <Container>
          <GuideTiles guides={guidance.featuredGuides} />
        </Container>
      </div>

      <CurveCarveTop variant="primary" background="light" />
      <AccordionCollection items={guidance.accordions} />

      <VideoTiles videos={guidance.featuredVideos} />

      <CurveCarveTop variant="light" background="primary" />
      <div className="bg-light  py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-center py-5">Guidance</h2>
            </Col>
          </Row>
          <Row>
            {guides.map(article => {
              return (
                <Col sm={6} lg={4} key={article.id} className="mx-auto">
                  <ArticleCard article={article} />
                </Col>
              )
            })}
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                as={Link}
                to="/news/guidance"
                size="lg"
                variant="secondary"
              >
                View More
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <CurveBulgeBottom variant="light" />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query GuidancePageQuery {
    contentfulGuidancePage(contentful_id: { eq: "2VeYY4ezr4WxBWcu1ZKJbR" }) {
      id
      contentful_id
      title
      introduction {
        introduction
        childMarkdownRemark {
          html
        }
      }
      featuredGuides {
        ...GuideFragment
      }
      accordions {
        ...AccordionFragment
      }
      featuredVideos {
        ...VideoFragment
      }
    }
    allContentfulArticle(
      filter: { categories: { elemMatch: { title: { eq: "Guidance" } } } }
      limit: 6
    ) {
      nodes {
        ...ArticleListFragment
      }
    }
  }
`

export default Guidance
