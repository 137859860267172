import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import ReactPlayer from "react-player/youtube"
import { FaCheckCircle } from "react-icons/fa"

export const VideoTiles = ({ videos = [] }) => {
  if (videos.length < 1) return null

  return (
    <div className="bg-primary text-white ">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center py-5">Videos</h2>
          </Col>
        </Row>
        <Row>
          {videos.map(video => (
            <Col key={video.id} md={4} className="mx-auto">
              <div className="embed-responsive embed-responsive-16by9">
                <ReactPlayer
                  url={video.youtubeUrl}
                  width="100%"
                  height="100%"
                />
              </div>
              <p className="lead mt-3">{video.title}</p>

              {video.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: video.description.childMarkdownRemark.html,
                  }}
                />
              )}
            </Col>
          ))}
        </Row>
        <Row>
          <Col md={8} className="mx-auto py-5 text-center">
            <h4>Want to see more? </h4>
            <p className="lead">
              Subcribe to our Youtube Channel for more insightful videos and
              guidance on property Investment
            </p>
            <Button
              className="my-3"
              variant="secondary"
              size="lg"
              href="https://www.youtube.com/channel/UCPzhqFNB4oMryw5egAL_oIw?sub_confirmation=1"
              target="_blank"
            >
              <FaCheckCircle className="mr-2 mb-1" />
              Subscribe
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
