import React, { useState } from "react"
import {
  Accordion,
  useAccordionToggle,
  Row,
  Container,
  Col,
} from "react-bootstrap"

import { CurveCarveTop } from "../Curves/Curve"
import slugify from "slugify"

import styled, { keyframes } from "styled-components"

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(25%);
  }
  40% {
    transform: translateY(-25%);
  }
  60% {
    transform: translateY(-15%);
  }
`

const StyledAccordionToggle = styled.button`
  /* Normalize Button */
  width: 100%;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  /* Style */
  padding: 15px 0;
  background:none;

  /* Icon */
  svg.triangle {
    display: block;
    margin: auto;
    width: 25px;
    height: 25px;
    transition: all 0.25s ease-in-out;
    fill: ${({ theme }) => theme.secondary};
  }

  &:hover {
    /* background: ${({ theme }) => theme.secondary}; */

    svg.triangle {
      fill: ${({ theme }) => theme.primary};
    }
  }

  &.open {
   
    svg.triangle {
      transform: rotate(180deg);
    }
  }

  &:not(.open) {
    svg.triangle {
      animation: ${bounce} 2s linear infinite;
    }
  }
`
const StyledAccordionBody = styled.div`
  padding: 30px 0;

  img {
    max-width: 100%;
  }
`

function CustomToggle({ eventKey, defaultOpen }) {
  const [open, setOpen] = useState(defaultOpen)

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setOpen(!open)
  })

  return (
    <StyledAccordionToggle
      className={open ? "open" : null}
      onClick={decoratedOnClick}
      aria-label="Toggle Accordion"
    >
      <svg className="triangle" viewBox="0 0 31 29">
        <path d="M15.5,29L0,0h31L15.5,29z" />
      </svg>
    </StyledAccordionToggle>
  )
}

export const AccordionAlt = ({
  defaultOpen = false,
  variant = "white",
  title = "Need a title",
  titleMobile = "Need a mobile title",
  introduction,
  children,
}) => {
  return (
    <div>
      <div
        id={`${slugify(title)}`}
        className={`bg-${variant} ${
          variant !== "white" ? "text-light" : "text-primary"
        } text-center`}
      >
        <div className="m-0 pt-4">
          <h3 className="d-md-none">{titleMobile}</h3>
          <h3 className="d-none d-md-block">{title}</h3>
        </div>

        <a href={`#${slugify(title)}`} aria-label={`Anchor link for ${title}`}>
          <svg height="16" version="1.1" viewBox="0 0 16 16" width="16">
            <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
          </svg>
        </a>
      </div>

      <CurveCarveTop variant="light" background={variant} />
      <div className="bg-light pt-5">
        <Container>
          <Row>
            <Col md={9} className="mx-auto">
              {introduction && (
                <div
                  className="accordion-body"
                  dangerouslySetInnerHTML={{
                    __html: introduction.childMarkdownRemark.html,
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>

        <Accordion defaultActiveKey={defaultOpen ? "0" : null}>
          <CustomToggle defaultOpen={defaultOpen} eventKey="0" />

          <Accordion.Collapse eventKey="0">
            <>
              <StyledAccordionBody>
                <Container>
                  <Row>
                    <Col md={9} className="mx-auto">
                      {children}
                    </Col>
                  </Row>
                </Container>
              </StyledAccordionBody>
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </div>
  )
}
